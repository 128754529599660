import type { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { BannerFormAlertVariant } from '@aurora/shared-client/components/common/BannerFormAlert/BannerFormAlert';
import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ActionButtonVariant,
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import {
  FormCheckInputType,
  FormInputFieldInputType
} from '@aurora/shared-client/components/form/enums';
import InputEditForm from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import type { FormSpec } from '@aurora/shared-client/components/form/types';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import {
  FormFeedbackPosition,
  FormFeedbackType
} from '@aurora/shared-client/helpers/form/FormFieldFeedback/enums';
import ModalFormBuilder from '@aurora/shared-client/helpers/modal/ModalFormBuilder/ModalFormBuilder';
import type { Form, User } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ErrorOption } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import { useUIDSeed } from 'react-uid';
import { BanDurations, BanMetrics } from '../../../types/enums';
import type {
  BanFragment,
  CreateBanRuleMutation,
  CreateBanRuleMutationVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import bansQuery from '../Bans.query.graphql';
import { isDefaultFieldValue } from '../BanUtil';
import DeleteBanAction from '../DeleteBanAction/DeleteBanAction';
import BanUserFormSchema from './BanUserForm.form.json';
import localStyles from './BanUserForm.module.pcss';
import createBanRuleMutation from './CreateBanRule.mutation.graphql';
import type { BanDurationOptions, BanMetricOptions } from './types';

export interface BanUserProps {
  /**
   * Member data to be banned.
   */
  user?: User;

  /**
   * Single member ban.
   */
  isSingleMemberBan?: boolean;

  /**
   * Callback function for toggling the display of ban modal.
   */
  onCloseBanModal?: () => void;
  /**
   * Whether the action being performed is an edit ban action.
   */
  isEditAction?: boolean;
  /**
   * BanFragment object containing the ban details.
   */
  banDetails?: BanFragment;
  /**
   * Callback to get triggered on submit of edit ban form data.
   *
   * @param formData edit ban form data.
   */
  onUpdateBanFormSubmit?: (formData: BanUserFormData) => void;
  /**
   * Whether the user is banned from user abuse dashboard.
   */
  isUserAbuseBan?: boolean;
}

export interface BanUserFormData {
  /**
   * Username getting banned
   */
  userName: string;
  /**
   * UserId getting banned
   */
  userId: number;
  /**
   * Email of the user getting banned
   */
  email: string;
  /**
   * Ip of the user getting banned
   */
  ip: string;
  /**
   * Whether the conditions should match one or all criteria.
   */
  isOr: boolean;
  /**
   * End date of the ban
   */
  banEndDate: number;
  /**
   * Duration of the ban
   */
  banDuration: number;
  /**
   * Custom duration of the ban
   */
  banDurationCustom: number;
  /**
   * Internal reason for ban
   */
  banReasonInternal: string;
  /**
   * Public reason for ban
   */
  banReasonPublic: string;
  /**
   * Ban duration metric for custom duration
   */
  banDurationMetric: number;
  /**
   * Reject all content of banned user as spam
   */
  rejectAllContentAsSpam: boolean;
  /**
   * Revoke all kudos given by banned user
   */
  revokeAllKudosGiven: boolean;
}

export const durationTypes: BanDurationOptions[] = [
  {
    key: '12Hours',
    value: BanDurations.TWELVE_HOURS
  },
  {
    key: '1Day',
    value: BanDurations.ONE_DAY
  },
  {
    key: '7Days',
    value: BanDurations.SEVEN_DAYS
  },
  {
    key: '14Days',
    value: BanDurations.FOURTEEN_DAYS
  },
  {
    key: '30Days',
    value: BanDurations.THIRTY_DAYS
  },
  {
    key: 'Custom',
    value: BanDurations.CUSTOM
  },
  {
    key: 'Permanent',
    value: BanDurations.PERMANENT
  }
];

export const durationMetricTypes: BanMetricOptions[] = [
  {
    key: 'Days',
    value: BanMetrics.DAYS
  },
  {
    key: 'Hours',
    value: BanMetrics.HOURS
  },
  {
    key: 'Minutes',
    value: BanMetrics.MINUTES
  }
];

/**
 * Ban User Form
 *
 * @author Vishnu Das
 */
const BanUserForm: React.FC<React.PropsWithChildren<BanUserProps>> = ({
  user,
  onCloseBanModal,
  isSingleMemberBan,
  isEditAction,
  banDetails,
  onUpdateBanFormSubmit,
  isUserAbuseBan
}) => {
  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.BAN_USER_FORM);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.BAN_USER_FORM);
  const uidSeed = useUIDSeed();
  const { addToast } = useToasts();
  const { formatAbsoluteDateTime } = useDateTime();
  const [showDuration, setShowDuration] = useState<boolean>(false);
  const naturalNumberValidationRegex = new RegExp(/^[1-9]\d*$/);
  const validationRegexForIPV4 = new RegExp(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/);
  const ipV6RegexString =
    '/(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}' +
    '|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]' +
    '{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a' +
    '-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4})' +
    '{0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-f' +
    'A-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gi';
  const validationRegexForIPV6 = new RegExp(ipV6RegexString);
  const client = useApolloClient();
  const [createBanMutation] = useMutationWithTracing<
    CreateBanRuleMutation,
    CreateBanRuleMutationVariables
  >(module, createBanRuleMutation);

  if (textLoading) {
    return null;
  }

  /**
   * *  - MATCH_ALL_REGEXP - default string value used in a ban in place of empty string when isOr is false.
   * $^ - MATCH_NO_REGEXP - default string value used in a ban in place of empty string when isOr is true.
   * -2 - MATCH_NO_USERS - default int value used for an empty userId ban field when isOr is true.
   * 0  - MATCH_ALL_USER_IDS - default int value used for an empty userId ban field when isOr is false.
   *
   * In case of edit form, when the form field has one of these values, then populate the form field as empty.
   */
  const formBuilder = new ModalFormBuilder<BanUserFormData>('BanUserForm', i18n, {
    schema: BanUserFormSchema as Form,
    cx
  })
    .addFieldGroupProperties(
      'banProperties',
      { className: cx('lia-g-mb-0 lia-g-mt-5') },
      {
        legend: {
          label: formatMessage('banProperties'),
          className: cx('lia-g-mb-0 h5')
        },
        description: {
          className: cx('text-body lia-g-text-xs lia-g-mb-15')
        }
      }
    )
    .addFieldGroupProperties(
      'banActions',
      { className: cx('lia-g-mb-0') },
      {
        legend: {
          label: formatMessage('banActions'),
          className: cx('lia-g-mb-15 h5')
        }
      }
    )
    .addFieldGroupProperties(
      'banReasons',
      { className: cx('lia-g-mb-0') },
      {
        legend: {
          label: formatMessage('banReasons'),
          className: cx('lia-g-mb-15 h5')
        }
      }
    )
    .addInputField({
      name: 'userName',
      inputType: FormInputFieldInputType.TEXT,
      formGroupSpec: {
        showInfo: true
      },
      defaultValue:
        !isEditAction || isDefaultFieldValue(banDetails?.loginRegexp)
          ? ''
          : banDetails?.loginRegexp,
      isVisible: {
        watchFields: null,
        callback: () => !isSingleMemberBan
      }
    })
    .addInputField({
      name: 'userId',
      inputType: FormInputFieldInputType.NUMBER,
      formGroupSpec: {
        showInfo: true
      },
      defaultValue:
        !isEditAction || isDefaultFieldValue(undefined, banDetails?.userId)
          ? undefined
          : banDetails?.userId,
      validations: {
        pattern: {
          value: naturalNumberValidationRegex,
          message: formatMessage('BanUserForm.userId.pattern.error')
        }
      },
      isVisible: {
        watchFields: null,
        callback: () => !isSingleMemberBan
      }
    })
    .addInputField({
      name: 'email',
      inputType: FormInputFieldInputType.TEXT,
      formGroupSpec: {
        showInfo: true
      },
      defaultValue:
        !isEditAction || isDefaultFieldValue(banDetails?.emailRegexp)
          ? ''
          : banDetails?.emailRegexp,
      isVisible: {
        watchFields: null,
        callback: () => !isSingleMemberBan
      }
    })
    .addInputField({
      name: 'ip',
      inputType: FormInputFieldInputType.TEXT,
      formGroupSpec: {
        showInfo: true
      },
      defaultValue:
        !isEditAction || isDefaultFieldValue(banDetails?.ipRegexp) ? '' : banDetails?.ipRegexp,
      validations: {
        validate: {
          validateIp: ip => {
            return ip === '' || validationRegexForIPV4.test(ip) || validationRegexForIPV6.test(ip);
          }
        }
      },
      isVisible: {
        watchFields: null,
        callback: () => !isSingleMemberBan
      }
    })
    .addCheckField({
      name: 'isOr',
      inputType: FormCheckInputType.CHECKBOX,
      defaultValue: isEditAction ? banDetails?.isOr : false,
      isVisible: {
        watchFields: null,
        callback: () => !isSingleMemberBan
      }
    })
    .addPlaceholderFormField({
      name: 'banEndDate',
      isVisible: {
        watchFields: null,
        callback: () => isEditAction && !showDuration
      },
      Content: (() => {
        return (
          <div className={cx('d-flex align-items-center')}>
            <small className={cx('lia-g-text-xs lia-g-divider')}>
              {banDetails?.dateEnd != '-1'
                ? formatMessage('BanUserForm.banEndDate.value', {
                    banEndDate: formatAbsoluteDateTime(Number(banDetails?.dateEnd))
                  })
                : formatMessage('permanentBan')}
            </small>
            <Button
              variant={ButtonVariant.LINK}
              size="sm"
              className={cx('lia-g-divider lia-g-p-0')}
              onClick={() => setShowDuration(true)}
            >
              {banDetails?.dateEnd != '-1'
                ? formatMessage('editEndDate')
                : formatMessage('changeEndDate')}
            </Button>
          </div>
        );
      }) as React.FC<React.PropsWithChildren<unknown>>
    })
    .addDropdownSelectField({
      name: 'banDuration',
      values: durationTypes,
      isVisible: {
        watchFields: null,
        callback: () => (isEditAction ? showDuration : true)
      },
      defaultValue: isEditAction
        ? banDetails?.minutesToBan == 0
          ? BanDurations.PERMANENT
          : BanDurations.CUSTOM
        : null
    })
    .addInputField({
      name: 'banDurationCustom',
      inputType: FormInputFieldInputType.TEXT,
      defaultValue:
        isEditAction && banDetails?.dateEnd != '-1'
          ? Math.round((Number(banDetails.dateEnd) - Date.now()) / 60000)
          : 0,
      isVisible: {
        watchFields: ['banDuration'],
        callback: formData =>
          isEditAction
            ? showDuration && formData.banDuration === BanDurations.CUSTOM
            : formData.banDuration === BanDurations.CUSTOM
      },
      validations: {
        validate: {
          banDurationCustom: (value: number) => {
            if (value > 0 && value % 1 !== 0) {
              return formatMessage('BanUserForm.banDurationCustom.decimal.validate.error');
            }
            if (!naturalNumberValidationRegex.test(value.toString())) {
              return formatMessage('BanUserForm.banDurationCustom.pattern.error', {
                min: '0'
              });
            }
            return true;
          }
        },
        required: true
      }
    })
    .addDropdownSelectField({
      name: 'banDurationMetric',
      values: durationMetricTypes,
      defaultValue:
        isEditAction && banDetails?.dateEnd != '-1' ? BanMetrics.MINUTES : BanMetrics.DAYS,
      isVisible: {
        watchFields: ['banDuration'],
        callback: formData =>
          isEditAction
            ? showDuration && formData.banDuration === BanDurations.CUSTOM
            : formData.banDuration === BanDurations.CUSTOM
      }
    })
    .addCheckField({
      name: 'rejectAllContentAsSpam',
      inputType: FormCheckInputType.CHECKBOX,
      label: formatMessage('BanUserForm.rejectAllContentAsSpam.label'),
      defaultValue: false,
      className: cx('lia-g-mb-10'),
      formGroupSpec: {
        showInfo: true
      },
      isVisible: {
        watchFields: null,
        callback: () => isSingleMemberBan
      }
    })
    .addCheckField({
      name: 'revokeAllKudosGiven',
      inputType: FormCheckInputType.CHECKBOX,
      label: formatMessage('BanUserForm.revokeAllKudosGiven.label'),
      defaultValue: false,
      isVisible: {
        watchFields: null,
        callback: () => isSingleMemberBan
      }
    })
    .addTextAreaField({
      name: 'banReasonInternal',
      formGroupSpec: {
        showInfo: true
      },
      defaultValue: isEditAction ? banDetails?.bannedReason : '',
      autoResize: false,
      rows: 3
    })
    .addTextAreaField({
      name: 'banReasonPublic',
      formGroupSpec: {
        showInfo: true
      },
      defaultValue: isEditAction ? banDetails?.publicBannedReason : '',
      autoResize: false,
      rows: 3
    })
    .setActionsClassName(cx('lia-actions'))
    .setActionsInnerContainerClassName(cx('lia-inner'))
    .setActionsOuterContainerClassName(cx('lia-g-locked-modal-footer'));

  if (isEditAction) {
    formBuilder.addFormAction({
      actionId: 'updateBan',
      buttonSpec: {
        buttonType: LoadingButtonVariant.LOADING_BUTTON,
        variant: ButtonVariant.PRIMARY
      }
    });
  } else {
    formBuilder.addFormAction({
      actionId: 'createBan',
      buttonSpec: {
        buttonType: LoadingButtonVariant.LOADING_BUTTON,
        variant: ButtonVariant.PRIMARY
      }
    });
  }

  formBuilder.addCancelAction();

  const formSpecs: FormSpec<BanUserFormData> = formBuilder.build();

  /**
   * Validating ban.
   *
   * @param formData form submit data
   * @param setError set error call back
   * @param setFormFeedback set form feedback call back
   */
  function banValidation(
    formData: BanUserFormData,
    setError: (name: FieldPath<BanUserFormData>, error: ErrorOption) => void,
    setFormFeedback
  ): boolean {
    const { banDuration, banDurationCustom, ip, userId, userName, email } = formData;
    if (banDuration === null) {
      setError('banDuration', {
        type: 'manual',
        message: formatMessage('BanUserForm.banDuration.error')
      });
      return false;
    } else if (
      (banDuration === BanDurations.CUSTOM && !banDurationCustom) ||
      banDurationCustom < 0
    ) {
      setError('banDurationCustom', {
        type: 'manual',
        message: formatMessage('BanUserForm.banDurationCustom.pattern.error', { min: '0' })
      });
      return false;
    }
    if (!isSingleMemberBan && !(ip || userId || userName || email)) {
      setFormFeedback({
        message: formatMessage('BanUserForm.banRule.properties.error'),
        variant: {
          type: FormFeedbackType.BANNER,
          alertVariant: BannerFormAlertVariant.DANGER
        },
        position: FormFeedbackPosition.TOP
      });
      return false;
    }
    return true;
  }

  /**
   * Renders failure toast messages
   * @param alertVariant The variant for the alert message
   * @param title Title to be displayed
   * @param body Toast body
   * @param autohide whether the toast should be hidden automatically after certain time interval
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    title: string,
    body: string,
    autohide = true
  ): void {
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id: uidSeed(title),
      toastVariant,
      alertVariant,
      title,
      autohide,
      message: body,
      delay: 4000
    };
    addToast(toastProps);
  }

  /**
   * Handles mutation response from create ban mutation.
   *
   * @param fetchResult result fetch from mutation
   */
  function handleMutationResult(fetchResult: FetchResult<CreateBanRuleMutation>): void {
    const { createBan: { errors: knownErrors = [] } = {} } = fetchResult?.data ?? {};
    const errors = fetchResult?.errors;
    if (knownErrors && knownErrors.length > 0) {
      if (knownErrors[0].__typename === 'BulkActionsError') {
        onCloseBanModal();
        renderToast(
          ToastAlertVariant.DANGER,
          formatMessage('bulkActionFailed.header'),
          formatMessage('bulkActionFailed.message')
        );
      } else if (knownErrors[0].__typename === 'CreateBanError') {
        renderToast(
          ToastAlertVariant.DANGER,
          isSingleMemberBan
            ? formatMessage('banCreationFailed.header')
            : formatMessage('banCreationRuleFailed.header'),
          knownErrors[0].message
        );
      }
    } else if (errors && errors.length > 0) {
      onCloseBanModal();
      renderToast(
        ToastAlertVariant.DANGER,
        isSingleMemberBan
          ? formatMessage('banCreationFailed.header')
          : formatMessage('banCreationRuleFailed.header'),
        isSingleMemberBan
          ? formatMessage('banCreationFailed.message')
          : formatMessage('banCreationRuleFailed.message')
      );
    } else {
      onCloseBanModal();
      renderToast(
        ToastAlertVariant.SUCCESS,
        isSingleMemberBan
          ? formatMessage('banCreationSuccess.header')
          : formatMessage('banCreationRuleSuccess.header'),
        isSingleMemberBan
          ? formatMessage('banCreationSuccess.message', { login: user?.login })
          : formatMessage('banCreationRuleSuccess.message')
      );
    }
  }

  /**
   * Makes the mutation call with the form data.
   *
   * @param formData data available from the ban form
   */
  async function createBan(formData: BanUserFormData): Promise<void> {
    const {
      banDuration,
      banDurationCustom,
      banReasonPublic,
      banReasonInternal,
      banDurationMetric,
      rejectAllContentAsSpam,
      revokeAllKudosGiven,
      ip,
      userId,
      userName,
      email,
      isOr
    } = formData;
    const createBanRuleMutationVariable = {
      banInput: {
        userId: isSingleMemberBan ? user?.uid : userId ?? 0,
        ipRegexp: isSingleMemberBan ? '' : ip,
        loginRegexp: isSingleMemberBan ? user?.login : userName,
        emailRegexp: isSingleMemberBan ? user?.email : email,
        loginExact: isSingleMemberBan && !!user?.login,
        emailExact: isSingleMemberBan && !!user?.email,
        isOr: isSingleMemberBan || isOr,
        minutesToBan:
          banDuration === BanDurations.CUSTOM ? banDurationCustom * banDurationMetric : banDuration,
        bannedReason: banReasonInternal,
        publicBannedReason: banReasonPublic
      },
      rejectContent: rejectAllContentAsSpam,
      revokeKudos: revokeAllKudosGiven
    };

    const creatBanRuleMutationOptions: MutationFunctionOptions<
      CreateBanRuleMutation,
      CreateBanRuleMutationVariables
    > = {
      variables: createBanRuleMutationVariable,
      refetchQueries: [
        {
          query: bansQuery
        }
      ]
    };

    const fetchResult: FetchResult<CreateBanRuleMutation> = await createBanMutation(
      creatBanRuleMutationOptions
    );
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'bans',
      broadcast: false
    });

    handleMutationResult(fetchResult);
  }

  /**
   *  Renders the delete button.
   */
  function DeleteActionComponent(): React.ReactElement {
    return (
      <DeleteBanAction
        banId={`ban:${banDetails.id}`}
        className={cx('lia-delete')}
        actionButtonVariant={ActionButtonVariant.TOOLBAR_BUTTON}
      />
    );
  }

  function onSubmit(
    formData: BanUserFormData,
    action: string,
    event: React.FormEvent<HTMLFormElement>,
    setError: (name: FieldPath<BanUserFormData>, error: ErrorOption) => void,
    setFormFeedback
  ): void {
    if (action == 'cancel') {
      onCloseBanModal();
      return;
    }
    const valid = banValidation(formData, setError, setFormFeedback);
    if (valid) {
      if (isEditAction) {
        const updatedFormData = { ...formData };
        if (!showDuration) {
          updatedFormData.banDuration =
            banDetails?.dateEnd === '-1' ? BanDurations.PERMANENT : BanDurations.CUSTOM;
          updatedFormData.banDurationCustom = Math.round(
            (Number(banDetails.dateEnd) - Date.now()) / 60000
          );
          updatedFormData.banDurationMetric = BanMetrics.MINUTES;
        }
        if (!isUserAbuseBan) {
          onCloseBanModal();
        }
        onUpdateBanFormSubmit(updatedFormData);
      } else {
        if (isUserAbuseBan) {
          onUpdateBanFormSubmit(formData);
          return;
        }
        createBan(formData);
      }
    }
    return;
  }

  return (
    <InputEditForm<BanUserFormData>
      formSpec={formSpecs}
      onSubmit={onSubmit}
      actionContextComponent={isEditAction && !isUserAbuseBan && DeleteActionComponent}
    />
  );
};

export default BanUserForm;
